.pc_spp_view {
  .module-spp-detail {
    .container {
      .col2 {
        .support {
          #lpbuttonvideo {
            display: block;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

#header,
#navigation {
  .outer-wrap {
    max-width: 1140px;
  }
}

.offer-promo-carousel-v1 {
  width: 400px;
}

.pc_spp_view .sticky_content .spp_product_status {
  clear: none;
}

.pc_spp_view {
  #main {
    .price {
      margin-bottom: 20px;
      #product-afterpay-logo {
        background: url('/media/export/cms/afterpay/ap-logo.png') no-repeat;
        width: 100px;
        height: 25px;
        margin: 10px 0px 0px;
        vertical-align: middle;
        background-size: 100px;
      }
      #product-afterpay-installment-text {
        width: 390px;
        font-family: $base-thin-font-family;
        #afterpay-learnmore {
          color: black;
          text-transform: uppercase;
          text-decoration: underline;
          font-size: 15px;
        }
      }
    }
  }
}

#footer .footer-bottom {
  .afterpay-content,
  .afterpay-content-mb {
    display: none !important;
  }
}

body.pc_spp_view {
  .ingredients-section {
    margin: 20px 0;
    .ingredients_header {
      cursor: pointer;
    }
    .ingredients_content {
      padding-top: 3px;
    }
    .ingredients_more {
      background-image: url(/media/export/cms/images/toggle_plus.png);
    }
    .ingredients_less {
      background-image: url(/media/export/cms/images/minus_icon.png);
      margin-bottom: 4px;
    }
    .ingredients_toggle {
      display: inline-block;
      height: 10px;
      width: 10px;
    }
  }
}

.pc_spp_view {
  #main {
    .price {
      border-top: none;
      padding-top: 0px;
    }
  }
}

.module-spp-detail {
  .price.price2 {
    div {
      color: #333;
    }
  }
}

li#quickshop-wrapper {
  padding: 0;
  max-width: 1000px;
  margin: 0 auto;
  list-style: none;
}

.spp_page_wrap {
  .container {
    .product-full {
      &__media {
        display: block;
        vertical-align: top;
        display: inline-block;
        @media #{$medium-up} {
          width: 48%;
          display: inline-block;
          margin-bottom: 20px;
        }
        .product-full {
          &__image {
            display: inline-block;
            vertical-align: top;
            margin: 0 auto;
            position: relative;
            max-width: 402px;
            &-wrapper {
              display: inline-block;
              position: relative;
              vertical-align: top;
            }
            &--slide {
              @media #{$medium-up} {
                display: none;
              }
              &.active {
                @media #{$medium-up} {
                  display: block;
                }
              }
            }
            &--slide,
            &--slide img {
              width: 402px;
              height: 464px;
            }
            &__video {
              position: relative;
              &.slick-slide {
                img.mobile-hidden {
                  display: none;
                }
              }
              &-overlay {
                background-image: url('/media/images/icons/video_play.png');
                background-repeat: no-repeat;
                background-position: top right;
                padding: 20px;
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
              }
              .poster-frame {
                width: 100%;
              }
            }
          }
          &__alt-images {
            display: inline-block;
            vertical-align: top;
            position: relative;
            &-slider {
              display: none;
              margin: 60px 20px 0 0;
              width: 50px;
              @media #{$medium-up} {
                display: none;
              }
              &.active {
                @media #{$medium-up} {
                  display: inline-block;
                }
              }
              .slick-slide {
                border: none;
              }
              .slick-arrow {
                height: 6px;
                width: 11px;
                left: 20px;
                position: relative;
                z-index: 100;
                padding: auto;
              }
              .slick-prev {
                top: -25px;
                background: url('/media/images/icons/spp_thumb_carousel_caret_up.png') no-repeat;
                &:before {
                  content: none;
                }
              }
              .slick-next {
                bottom: -25px;
                background: url('/media/images/icons/spp_thumb_carousel_caret_down.png') no-repeat;
                &:before {
                  content: none;
                }
              }
              &-button {
                height: 6px;
                width: 11px;
                margin: 0 auto;
                cursor: pointer;
              }
            }
            &-thumb {
              padding: 5px 0;
              cursor: pointer;
              border-bottom: 3px solid $color-white;
              &.active {
                border-color: $color-black;
              }
              img {
                width: 50px;
              }
            }
          }
          .badge {
            position: absolute;
            top: 50px;
            #{$ldirection}: 50px;
            display: table;
            width: 65px;
            height: 65px;
            text-align: center;
            @include border-radius(50%);
            color: #fff;
            z-index: 1;
            padding: 0;
            line-height: 14px;
            font-family: $base-font-family;
            vertical-align: middle;
            margin: 0;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            &--large {
              top: 0;
              left: 0;
            }
            &.badge_1,
            &.badge_2,
            &.badge_3,
            &.badge_4,
            &.badge_5,
            &.badge_17,
            &.badge_92,
            &.badge_94,
            &.badge_96,
            &.badge_97 {
              background: $color-cl-grey;
            }
            &.badge_96 {
              font-size: 12px;
            }
            &.badge_30 {
              background: $color-cl-grey;
            }
            html.ie7 & {
              text-align: center;
            }
            .badge-inner {
              display: table-cell;
              vertical-align: middle;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.sticky_prod_select {
  .giftset_components {
    .product-size {
      &__non-shaded {
        display: none;
      }
    }
  }
}
