div.csr_phone_number {
  display: none;
}

.page-wrapper,
.wrapper {
  font-size: 15px;
  .brand-id-Clinique {
    h3 {
      font-size: 3em;
      font-weight: normal;
      margin-bottom: 0;
      text-decoration: underline;
    }
    h4 {
      font-size: 2.25em;
      font-weight: normal;
    }
  }
}
